import * as React from "react"
import styled from 'styled-components';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Text from '../components/Text';
import { StaticImage } from 'gatsby-plugin-image';
import { theme } from '../style/theme';
import { device } from "../components/Devices";



const Section = styled.section`
    display: flex;
    flex-direction: row;
    padding: ${theme.paddings.main};
    padding-top: 100px !important;
    margin-top: 4%;
    width: 100%;

    @media ${device.laptopM} {
        padding: ${theme.paddings.mainLaptopM};
    }

    @media ${device.laptop} {
        padding: ${theme.paddings.mainLaptop};
    }

    @media ${device.tablet} {
        padding: ${theme.paddings.mainTablet};
        margin-top: 0;
        flex-direction: column;
    }
`

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;

    span {
        margin: 8% 0;
        line-height: 1.2;
        white-space: pre-line;
        width: 100%;
    }

    .envases-reciclame{
        margin-top: 8%;
        width: 68% !important;
    }

    .envases-title {
        width: 130%;
    }


    @media ${device.tablet}{
        align-items: center;
        justify-content: center;
        width: 100%;

        > span{
            margin: 4% 0;
        }

        .envases-title, .envases-text{
            text-align: left;
            width: 100%;
        }

        .envases-reciclar {
            display: none;
        }

        .envases-reciclame {
            display: none;
        }

    }

    @media ${device.mobileL} {
        .envases-title {
            margin: 4% 0;
        }
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 8%;
    width: 70%;

    .envases-text-one{
        padding-right: 2%;
    }
    .envases-text-two{
        padding-left: 2%;
    }

    .envases-reciclar-footer, .envases-reciclame-footer{ display: none; }

    @media ${device.tablet} {
        padding: 0;
        align-items: center;
        width: 100%;

        .envases-reciclar-footer{
            display: block;
            width: 50%;
        } 
        .envases-reciclame-footer{ 
            display: block; 
            margin: 5% 0;
            width: 30%;
        }
    }

    @media ${device.mobileL} {
        .envases-text-one{
            padding-right: 0;
        }

        .envases-text-two{
            padding-left: 0;
        }
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    margin-bottom: 10%;
    justify-content: center;
    width: 100%;

    @media ${device.tablet}{
        margin-bottom: 5%;
        flex-direction: column;
        line-height: 1;
    }

    @media ${device.mobileL} {

    }
`;

const TextWrapper = styled.div`
    white-space: pre-line;
    width: 50%;

    h2 {
        color: ${theme.colors.text.h2};
        font-family: ${theme.fonts.type.h2};
        font-size: 1rem;
        margin-top: 5%;
    }

    span {
        line-height: 1.3;
    }

    @media ${device.tablet} {
        padding-left:0 !important;
        width: 100%;
        
        span {
            font-size: 0.8rem;
        }
    }

    @media ${device.mobileL}{
        span{
            font-size: 1rem;
            line-height: 1;
        }
    }
` ;


const EnvasesPage = () => {

    return (
        <main>
        <Layout>
            <Section>
                <LeftContainer>
                    <Title className='envases-title' color={theme.colors.text.main} title='Envases que protegen'/>
                    <Text className={'envases-text'}
                            text={`Nuestros envases fueron pensados no sólo para proteger el alimento de nuestras mascotas, sino también el mundo que nos rodea.`}/>
                    <StaticImage src="../images/Envases/left-img1.png" alt="Livra Recicla" className='envases-reciclar' layout="fullWidth" placeholder="blurred" quality={100}/>
                    <StaticImage src="../images/Envases/reciclame.png" alt="Reciclame" className='envases-reciclame' layout="fullWidth" placeholder="blurred" quality={100}/>
                </LeftContainer>
                <RightContainer>
                    <StaticImage src='../images/Envases/central-img.png' alt="Valores LIVRA" placeholder="blurred"/>
                    <TextContainer>
                        <TextWrapper className='envases-text-one'>
                            <h2>ENVASES FUERTES Y PRÁCTICOS</h2>
                            <Text className={'envases-text'} 
                                text={`Nuestros envases están desarrollados con un sólo material, el polietileno, y están compuestos por siete capas que permiten cumplir con las más altas exigencias de una bolsa premium, pero con la ventaja de ser un material totalmente reciclable, en el que se incorpora material reutilizado.
                                Los beneficios de estas capas son múltiples. Entre ellos, se destaca su alta barrera contra la humedad, las grasas y la luz, lo que garantiza una protección efectiva del alimento (a la altura de los envases que utilizan películas metalizadas). Pero lo más importante es su capacidad de prolongar la conservación del alimento.`}
                            />
                        </TextWrapper>
                        <TextWrapper className='envases-text-two'>
                            <h2>RECICLABILIDAD</h2>
                            <Text className={'envases-text'} 
                                text={`El uso de envases que contienen película metalizada presenta limitaciones en su reciclaje, ya que resulta imposible separarlos para poder reutilizarlos, lo que genera una gran huella de carbono en el planeta.
                                Nuestra bolsa es libre de metal y está hecha totalmente de polietileno porque somos conscientes de la importancia de nuestra responsabilidad ambiental. Utilizamos tecnología ECOTEC, donde se reemplaza el poliéster que se utiliza habitualmente por resinas especiales 100% reciclables. A través del desarrollo de nuestros envases, nos comprometemos con la economía circular.`}
                            />
                        </TextWrapper>
                    </TextContainer>

                    <StaticImage src="../images/Envases/left-img1.png" alt="Livra Recicla" className='envases-reciclar-footer' layout="fullWidth" placeholder="blurred" quality={100}/>
                    <StaticImage src="../images/Envases/reciclame.png" alt="Reciclame" className='envases-reciclame-footer' layout="fullWidth" placeholder="blurred" quality={100}/>
                </RightContainer>
            </Section>
        </Layout>
        </main>
  );
}

export default EnvasesPage;

export { Head } from "../components/Head";